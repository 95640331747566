import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { MailIcon, PhoneIcon, SunIcon } from "@heroicons/react/solid";
import ContactForm from "../components/form/ContactForm";

const KontaktPage = ({ location }) => {
    // Move this to it's own component consuming context.

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Kontakt | Domy Trio</title>
            </Helmet>
            <Layout location={location}>
                <main>
                    <div className="mx-auto w-full  relative z-30 ">
                        <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">
                            <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                                <polygon
                                    points="0,0 300,0 300,300"
                                    class="triangle"
                                    fill="currentColor"
                                />
                                <SunIcon
                                    width="50"
                                    x="160"
                                    y="-70"
                                    fill="#ffffff"
                                />
                            </svg>
                            <div className="relative">
                                <div className="">
                                    <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">
                                        Kontakty
                                    </h1>
                                </div>
                                <div>
                                    <dl className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2">
                                        <div className="relative bg-gray-100 pt-5 px-4  sm:pt-6 sm:px-6 shadow  overflow-hidden">
                                            <dt>
                                                <div className="absolute bg-yellow-400  p-3">
                                                    <PhoneIcon
                                                        className="h-6 w-6 text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                                    Telefon
                                                </p>
                                            </dt>
                                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900">
                                                    +420 607 088 977
                                                </p>
                                            </dd>
                                        </div>
                                        <a
                                            href="mailto:kamenice@domytrio.cz"
                                            className="relative bg-gray-100 pt-5 px-4  sm:pt-6 sm:px-6 shadow  overflow-hidden"
                                        >
                                            <dt>
                                                <div className="absolute bg-yellow-400   p-3">
                                                    <MailIcon
                                                        className="h-6 w-6 text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                                                    E-mail
                                                </p>
                                            </dt>
                                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900">
                                                    kamenice@domytrio.cz
                                                </p>
                                            </dd>
                                        </a>
                                    </dl>

                                    <div className="prose pt-10 pb-3">
                                        <p>
                                            Jsme tu pro Vás. Obraťte se na nás
                                            se svými dotazy nebo připomínkami,
                                            těšíme se na Vaši zprávu!
                                        </p>
                                    </div>
                                    <div className="relative bg-gray-100  px-4 mt-3 sm:px-6 shadow  overflow-hidden">
                                        <ContactForm />
                                    </div>

                                    <p className="text-base text-gray-500 font-bold"></p>

                                    <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase mt-10 ">
                                        TRIA NA SLUNNÉM VRŠKU s.r.o.
                                    </h3>
                                    <p className="text-base text-gray-500">
                                        Nádražní 47/90 | 150 00 Praha 5
                                    </p>
                                    <p className="text-base text-gray-500">
                                        IČ 10753524
                                    </p>
                                    <p className=" text-gray-500 text-xs my-5">
                                        Spisová značka C 347837 vedená u
                                        Městského soudu v Praze
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </>
    );
};

export default KontaktPage;
